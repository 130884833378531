.footer {
    border-top: 1px solid #eaeaea;
    background-color: #1B67AC;
    color: white;
}

.footer a{
    cursor: pointer;
}

.footer a:hover{
    text-decoration: underline;
}